import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Banner = () => (
    <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
        reviews: wordpressPage(slug: { eq: "reviews" }) {
            featured_media {
                source_url
            }
            acf {
                reviews_heading
            }
        }
        allWordpressWpReviews(sort:{order:DESC, fields:date}) {
            edges {
              node {
                id
                title
                content
                acf {
                  date
                  country
                }
              }
            }
          }
    }
    `} render={props => (
         <div className="frame">
            <div className="reviews-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="heading">{ props.reviews.acf.reviews_heading }</div>
                <div className="all-reviews">
                    {
                        props.allWordpressWpReviews.edges.map(reviewItem => (
                        <div className="item" key={reviewItem.node.id} data-aos="fade-up">
                            <div className="title" dangerouslySetInnerHTML = {{ __html: reviewItem.node.title }}></div>
                            <div className="content" dangerouslySetInnerHTML = {{ __html: reviewItem.node.content }}></div>
                            <div className="location-country">
                                <span className="location">{reviewItem.node.acf.date}</span>
                                {reviewItem.node.acf.country ? (
                                    <span className="country">,&nbsp;{reviewItem.node.acf.country}</span>
                                ) : null}
                            </div>
                        </div>   
                        ))
                    }
                </div>
            </div>
         </div>
    ) } />
);

export default Banner;